/*
Purpose: list details of FREQUENCY with use datatable
Developer: BY M.KTH
Date:07/10/2024
Project: TCMS
*/
import React from 'react';
import { useState, useCallback, useEffect } from "react";
import { NavLink,useNavigate } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { security_key, BASE_URL} from "../global";




const ListFrequency = () => {
  
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleRowEditViewClick = async(e) => {
   
    // if(parseInt(e.fk_cent_key) == 0 || e.subject_id==1 || e.subject_id==2){

    //   toast.error("Sorry! This subject is set as default and cannot be edited", {
    //     autoClose: 3000, // 3 seconds in this example
    // });
    // }else{
        navigate(`/EditFrequency`, { state: { pk_fm_key: `${e.pk_fm_key}` }});
   // }
    
 }

   // Sample data source
    
     //Api call start
     const [data, setItems] = useState([]);
    
     useEffect(() => {
         fetchData();
     }, []);

     const fetchData = async () => {
         try {
          setLoading(true);
             const response = await axios.post(BASE_URL+'API/api_index.php', {
               security_key: security_key,
               mode:"getAllFrequencyCentre",
               fk_cent_key: sessionStorage.getItem('key')
             });
             if(response.data.success===true){
               setRecords(response.data.data);
               setItems(response.data.data);
               setLoading(false);

             }else{
               //alert message provide
               setLoading(false);

             }
             
             
         } catch (error) {
             console.error('Error fetching data:', error);
         }
     };

     //Api call End

     function getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
        // If the day is between 11 and 13, use 'th' suffix
        return day + 'th';
      }
    
      const lastDigit = day % 10;
      switch (lastDigit) {
        case 1:
          return day + 'st';
        case 2:
          return day + 'nd';
        case 3:
          return day + 'rd';
        default:
          return day + 'th';
      }
    }
    
    function getDayOfWeek(dayNumber) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
       
      ];
    
      if (dayNumber >= 1 && dayNumber <= 7) {
        return daysOfWeek[dayNumber - 1];
      } else {
        return "Invalid day number";
      }
    }

// Sample columns configuration
const columns = [
 
{ 
  
  headerName: 'Frequency Name',
  field: "Frequency Name",
  headerClassName: "super-app-theme--header",
  flex: 1,
  valueGetter: (params) => {
    return `${params.row.fm_name == null || params.row.fm_name == '' ? '-' : params.row.fm_name}`;
  },

 },
  { 
    
    headerName: 'Frequency Duration Type',
    field: "Frequency Duration Type",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.fm_type == null || params.row.fm_type == '' ? '-' : parseInt(params.row.fm_type) === 1 ? 'Days' : parseInt(params.row.fm_type) === 2 ? 'Months' : '-'}`;
    },
  },
  { 
    headerName: 'Frequency Duration',
    field: "Frequency Duration",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.fm_duration == null || params.row.fm_duration == '' ? '-' : params.row.fm_duration}`;
    },
    
  },
  { 
    headerName: 'Day',
    field: "Day",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.fm_type == null || params.row.fm_type == '' ? '-' : parseInt(params.row.fm_type)=== 1 ? getDayOfWeek(parseInt(params.row.fm_day_date)) : '-'}`;
    },
    
  },
  { 
    headerName: 'Date',
    field: "Date",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.fm_type == null || params.row.fm_type == '' ? '-' : parseInt(params.row.fm_type)=== 2 ? getDayWithSuffix(parseInt(params.row.fm_day_date)) : '-'}`;
    },
    
  },
  { 
    headerName: 'Invoice Reminder(No of days)',
    field: "Invoice Reminder(No of days)",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.fm_invoice == null || params.row.fm_invoice == '' ? '-' : params.row.fm_invoice}`;
    },
    
  },
  
];

const [records, setRecords] = useState(data);


  return (

    <><ToastContainer position="top-center" />
    <div className="page-wrapper">

        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h5 className='page-title'> Payment Frequency  </h5>
                
                <div role="presentation" >
                    <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                        <Typography color="text.primary">Payment Frequency</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div className='d-flex align-items-center gap-2'>
                {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                <NavLink className="primary-filled rounded-10 p-2" to='/AddFrequency' >
                <AddIcon className="btn-icon"/> New Frequency</NavLink>
            </div>
          </div>

        

              {/* --------------Section Start--------------- */}  

              <Card className='my-card p-1 bg-white'>

                  <Card.Body>
                          
                      {/* -------------Content Start--------------- */}  

                      <Row>
                        <Col xs="12">
                            <div className="">

                                <Row className="justify-content-center">

                                    
                                    


                                    {/* --------------Table Content Start--------------- */} 
                                    <Col sm={12}>

                                    <Box
                                            sx={{
                                                height: "fit-content",
                                                width: "100%",
                                                "& .actions": {
                                                    color: "text.secondary",
                                                },
                                                "& .textPrimary": {
                                                    color: "text.primary",
                                                },
                                                "& .super-app-theme--header": {
                                                    backgroundColor: "#e3e6ee",
                                                },
                                            }}
                                            style={{ height: '100%', width: "100%" }}
                                        >
                                           <DataGrid
                                  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                                //sx={{ border: "none", m: 2 }}
                                                autoHeight
                                                slots={{
                                                    loadingOverlay: LinearProgress,
                                                    //noRowsOverlay: CustomNoRowsOverlay,
                                                    toolbar: GridToolbar,
                                                    // toolbar: EditToolbar,
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        csvOptions: { fileName: "SubjectList" },
                                                        printOptions: { disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "SubjectList",pageStyle: `@media print {
                                                          @page {
                                                            size: A4 landscape;
                                                            margin: 0;
                                                          }
                                                        }` },
                                                        records,
                                                        showQuickFilter: true
                                                    },


                                                }}
                                                initialState={{
                                                    ...records.initialState,
                                                    pagination: { paginationModel: { pageSize: 10 } },
                                                }}
                                                showQuickFilter={true}

                                                pageSizeOptions={[10, 25, 50, 100]}

                                                loading={loading}
                                                rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                                columns={columns}
                                                rowHeight={60} density="compact"
                                                onRowClick={(params) => handleRowEditViewClick(params.row)}

                                                disableRowSelectionOnClick
                                                showCellVerticalBorder={true}
                                                showColumnVerticalBorder={true}

                                            />


                                        </Box>
                                      
                                         

                                    </Col>
                                    {/* --------------Table Content Start--------------- */} 

                                </Row>
                                
                            </div>
                        </Col>
                      </Row>

                      {/* --------------Content End--------------- */}


                  </Card.Body>

              </Card>

              {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}

    </div>
    </>
);


};

export default ListFrequency;
