
/*
Purpose: add subject details
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import './add-student.css';
import { security_key, BASE_URL, options_days, options_months} from "../global";
import Select from 'react-select'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function AddFrequency() {

    const navigate = useNavigate();
    
    const [OptionDays, setOptionDays] = useState([]);
    const [OptionMonths, setOptionMonths] = useState([]);
      
//Api call End
useEffect(() => {
    const newDaysArray = [{ value: '', label: 'Select' }, ...options_days];
    setOptionDays(newDaysArray);
  
    const newMonthsArray = [{ value: '', label: 'Select' }, ...options_months];
    setOptionMonths(newMonthsArray);
  }, [options_days, options_months]);
  

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);
    const options_duration_type = [
        { value: '1', label: 'Days' },
        { value: '2', label: 'Months' }
      ];


      
    const basicSchema = yup.object().shape({
        fm_name : yup.string().required("Frequency name is required.").min(2, "Minimum 2 characters is required.").max(50, "Maximum 50 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        fm_type : yup.mixed().required("Frequency duration type is required."),
        sp_start_ofDay: yup
        .string()
        .nullable()
        .test('is-required', 'Day of Week is required', function (value) {
          const { sp_start_ofDay, sp_start_ofDay_mon, /* add more fields if needed */ } = this.parent;
    
          return !!sp_start_ofDay || !!sp_start_ofDay_mon; // Add more conditions if needed
        }),
    
      sp_start_ofDay_mon: yup
        .string()
        .nullable()
        .test('is-required', 'Day of Month is required', function (value) {
          const { sp_start_ofDay, sp_start_ofDay_mon, /* add more fields if needed */ } = this.parent;
    
          return !!sp_start_ofDay || !!sp_start_ofDay_mon; // Add more conditions if needed
        }),
        fm_duration: yup.number().integer().min(1, "Minimum value 1 required").max(12, "Maximum value 12 required").required("Frequency Duration is required."),
        fm_invoice: yup.number().integer().min(0, "Minimum value 0 required").max(7, "Maximum value 7 required").required("Invoice Reminder is required."),
    });

    const initialValues = {
        /*Frequency*/
       
        fm_name : '',
        fm_type : '',
        sp_start_ofDay: "",
        sp_start_ofDay_mon: "",
        fm_duration:'',
        fm_invoice:'',
        fk_cent_key: sessionStorage.getItem('key'),
        created_by: sessionStorage.getItem('key'),
        visibility: 1
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        onSubmit: async (formValues) => {
            // setIsSubmitting(true);
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    security_key: security_key,
                    mode: "createFrequency",
                    ...formValues,
                });
                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if (parseInt(response.data.data) === 1) {
                        toast.success("Success! Your frequency has been added", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/Frequency");
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                        setIsSubmitting(false); // Re-enable the submit button
                    }else if(parseInt(response.data.data) === 2){
                        toast.error("Sorry! Frequency name already exists.", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }else {
                        toast.error("Failure! Unable to create frequency. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to create frequency. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to create frequency. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
    };

    return (
        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>

                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'>New Frequency</h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">New Frequency</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                    </div>
                        {/* --------------Student Particulars--------------- */}
                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Frequency </h6>
                            </div>
                            <Row>
                                {/* --------------Student First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Frequency Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.fm_name}
                                        onChange={(e) => {
                                            handleChange(e);
                                          }}
                                        onBlur={handleBlur}
                                        id="fm_name"
                                        className={errors.fm_name && touched.fm_name ? "input-error" : ""}
                                    />
                                    {errors.fm_name && touched.fm_name && <p className="error">{errors.fm_name}</p>}
                                </Col>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Frequency Duration Type</h6>
                                    <Select menuPlacement="auto" options={options_duration_type}
                                        name="fm_type"
                                        value={options_duration_type.find(option => option.value && values.fm_type && option.value.toString() === values.fm_type.toString())}
                                        id="fm_type"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "fm_type",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            const event1 = {
                                                target: {
                                                    name: "sp_start_ofDay",
                                                    value: '',
                                                },
                                            };
                                            const event2 = {
                                                target: {
                                                    name: "sp_start_ofDay_mon",
                                                    value: '',
                                                },
                                            };
                                            handleChange(event2);
                                            setTouched({ ...touched, sp_start_ofDay_mon: true });

                                            handleChange(event1);
                                            setTouched({ ...touched, sp_start_ofDay: true });
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, fm_type: true });
                                        }}
                                        className={errors.fm_type && touched.fm_type ? "input-error" : ""}
                                    />
                                    {errors.fm_type && touched.fm_type && <span className="error">{errors.fm_type}</span>}

                                </Col>
                                {parseInt(values.fm_type) === 2 ?
                                  <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Select day of Month</h6>
                                    <Select menuPlacement="auto" options={OptionMonths}
                                        name="sp_start_ofDay_mon"
                                        value={OptionMonths.find(option => option && option.value.toString() === values.sp_start_ofDay_mon.toString())}
                                        id="sp_start_ofDay_mon"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sp_start_ofDay_mon",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sp_start_ofDay_mon: true });
                                        }}
                                        className={errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon ? "input-error" : ""}
                                    />
                                    {errors.sp_start_ofDay_mon && touched.sp_start_ofDay_mon && <span className="error">{errors.sp_start_ofDay_mon}</span>}

                                </Col>
                                :
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Select day of Week</h6>
                                    <Select menuPlacement="auto" options={OptionDays}
                                        name="sp_start_ofDay"
                                        value={OptionDays.find(option => option && option.value.toString() === values.sp_start_ofDay.toString())}
                                        id="sp_start_ofDay"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "sp_start_ofDay",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, sp_start_ofDay: true });
                                        }}
                                        className={errors.sp_start_ofDay && touched.sp_start_ofDay ? "input-error" : ""}
                                    />
                                    {errors.sp_start_ofDay && touched.sp_start_ofDay && <span className="error">{errors.sp_start_ofDay}</span>}

                                </Col> 
                               
                                }
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Frequency Duration {['top'].map((placement) => (
                                            <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                                <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                               Note: Frequency Duration refers to the following examples: a weekly frequency duration is 7 days, a monthly frequency duration is 1 month, and a term frequency duration is 4 months ect..
                                                </Tooltip>
                                            }
                                            >
                                                <Button variant="secondary" className="no-bdr">
                                                    <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            
                                        ))}</h6>
                                    <Form.Control
                                        type="number"
                                        id="fm_duration"
                                        
                                        value={values.fm_duration}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.fm_duration && touched.fm_duration ? "input-error" : ""}
                                    />
                                    {errors.fm_duration && touched.fm_duration && <p className="error">{errors.fm_duration}</p>}
                                </Col>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Invoice Reminder {['top'].map((placement) => (
                                            <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                                <Tooltip id={`tooltip-${placement}`} className="custom-tooltip">
                                               Note: Create the invoice before payment. This field refers to the number of days after the invoice is created.
                                                </Tooltip>
                                            }
                                            >
                                                <Button variant="secondary" className="no-bdr">
                                                    <i class="fa-solid fa-info-circle ml-2 mr-2 mt-1"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            
                                        ))}</h6>
                                    <Form.Control
                                        type="number"
                                        id="fm_invoice"
                                        
                                        value={values.fm_invoice}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.fm_invoice && touched.fm_invoice ? "input-error" : ""}
                                    />
                                    {errors.fm_invoice && touched.fm_invoice && <p className="error">{errors.fm_invoice}</p>}
                                </Col>
                               
                            </Row>
                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                         
                            <Button variant="contained" className="primary-gray me-2" onClick={() => navigate('/Frequency')}>Cancel</Button>
                        <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default AddFrequency;