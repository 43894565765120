/*
Purpose: list details of diagnostics with use datatable
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import React from 'react';
import Card from 'react-bootstrap/Card';
import AddIcon from '@mui/icons-material/Add';
import Form from "react-bootstrap/Form";
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import 'react-data-table-component-extensions/dist/index.css';
import { Modal} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { useFormik } from "formik";
import * as yup from "yup";

import './Student.css';
import { security_key, BASE_URL } from "../global";


function Fees() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);



  const handleRowClick = async (e) => {
   
    navigate(`/Edit-pricing`, { state: { pk_stp_id: `${e.pk_stp_id}` } });

  }

  const handleRowClick_discount = async (e) => {
   
    navigate(`/Edit-discount`, { state: { pk_discount_id: `${e.pk_discount_id}` } });

  }


  const [isActive, setIsActive] = useState('first');


  const handleClick = (eventKey) => {

    setIsActive(eventKey); // Update the active tab when a tab is clicked
  };

  console.log(isActive)
  const [records, setRecords] = useState([]);

  const [records_d, setRecords_d] = useState([]);


  //Api call start
  const [data, setItems] = useState([]);
  const [data_d, setItems_d] = useState([]);


  //Offline payment array
  const [OfflinePaymentData, setOfflinePaymentData] = useState([]);


  const [showDelete, setDeleteShow] = useState(false);
  const [data_delete, setDeleteLabelData] = useState([]);
  const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);


  const handleDeleteClick = (row) => {
   
    const updatedData = { ...row };
    setDeleteLabelData(updatedData);

    setDeleteShow(true);
  };
  const handleActivePopup = async (e) => {


      toast.error("Sorry! Already active this price.", {
        autoClose: 3000, // 3 seconds in this example
      });

   

  }

  function onSubmitDelete() {
    setIsUserDeleteSubmitting(true);

    const responsedelete = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "updatePriceActive",
      pk_stp_id: data_delete.pk_stp_id
    }).then((responsedelete) => {

      if (responsedelete.data.data == 1) {

        setDeleteShow(false)
        // Handle the successful response here
        toast.success("Success! Price activated.", {
          autoClose: 3000, // 3 seconds in this example
        });

        fetchData();

        setIsUserDeleteSubmitting(false);

      } else {
        toast.error("Failure! Unable to price has been active. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      }


    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to price has been active. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }

  useEffect(() => {
     fetchData();
     fetchOfflinePaymentData();
    
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getAllPrice",
        fk_cent_key: sessionStorage.getItem('key')
      });
     
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);

      } else {
        //alert message provide
        setLoading(false);

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchOfflinePaymentData = async () => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getOfflinePaymentCenter",
        fk_cent_key: sessionStorage.getItem('key')
      });
     
      if (response.data.success === true) {
        if(response.data.data.length > 0){
          setOfflinePaymentData(response.data.data);
        }else{
          setOfflinePaymentData([]);
        }
       
      } else {
        //alert message provide
        setLoading(false);

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const fetchData_d = async () => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getAllDiscount",
        fk_cent_key: sessionStorage.getItem('key')
      });
     
      if (response.data.success === true) {
        setRecords_d(response.data.data);
        setItems_d(response.data.data);
        setLoading(false);

      } else {
        //alert message provide
        setLoading(false);

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //Api call End


  const columns = [
    {
      headerName: 'Product Name',
      field: "Product",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.stp_product_name == null || params.row.stp_product_name == '' ? '-' : params.row.stp_product_name}`;
      },
    },
    {
      headerName: "Fees (" + (+sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD') + ")",
      field: "Fees",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.stp_price == null || params.row.stp_price == '' ? '-' : params.row.stp_price}`;
      },
    },{
      headerName: 'Stripe Price ID',
      field: "Price",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.stp_price_id == null || params.row.stp_price_id == '' ? '-' : params.row.stp_price_id}`;
      },
    },{
      headerName: 'Meter Name',
      field: "Meter",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.stp_price_meter == null || params.row.stp_price_meter == '' ? '-' : params.row.stp_price_meter}`;
      },
    },
    ,{
      headerName: 'Payment Frequency',
      field: "Payment",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sp_pay_freq == null || params.row.sp_pay_freq == '' ? '-' : parseInt(params.row.sp_pay_freq) === 1 ? 'Weekly': parseInt(params.row.sp_pay_freq) === 2 ? 'Monthly': '-' }`;
      },
    },
    {
      field: "actions",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 150,
      //flex: 1,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      // valueGetter: (params) => {
      //   return `${parseInt(params.row.visibility)===1? 'Active' : 'Inactive'}`;
      // },
      renderCell: (params) => {
        const row = params.row;

       
        return ( <div className='d-flex pad-12'>

              {parseInt(row.visibility) === 1 ?
                <span className='active-btn my-auto cursor-pointer' onClick={() => handleActivePopup(row)} >
                  <DoneIcon className="icon-blue  btn-icon me-1" />
                  Active
                </span> :
                <span className='deactive-btn my-auto cursor-pointer' onClick={() => handleDeleteClick(row)} >
                  <CloseIcon className=" btn-icon me-1" />
                  Inactive
                </span>}

              {/* <Buttonmui variant="outlined-red" onClick={() => openPopupGP(row)} className="primary-blue  btnmr-2 my-auto">Send Enrolment </Buttonmui> */}

            </div>);
         
       

      },
    },
  ];


  const columns_d = [
    {
      headerName: 'Title',
      field: "Title",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.discount_title == null || params.row.discount_title == '' ? '-' : params.row.discount_title}`;
      }
    },
    {
      headerName: 'Discount',
      field: "Discount",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.discount_cost == null || params.row.discount_cost == '' ? '-' : params.row.discount_cost}`;
      }
    },
  ];


  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission state
  const initialValues = {
    ...OfflinePaymentData.reduce((acc, method) => {
      acc[method.pk_cpm_key] = parseFloat(method.cpa_amount); // Convert to float
      return acc;
    }, {}),
    fk_cent_key: sessionStorage.getItem('key')
  };
  
  const validationSchema = yup.object().shape({
    ...OfflinePaymentData.reduce((acc, method) => {
      acc[method.pk_cpm_key] = yup.number()
        .typeError(`${method.pm_name} fees must be a number`)
        .required(`${method.pm_name} fees is required`)
        .min(0.01, `${method.pm_name} fees must greater than zero`);
      return acc;
    }, {}),
    
  });


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
    setIsSubmitted(true);
      try {
        // const offlineData = OfflinePaymentData.reduce((acc, method) => {
        //   acc[method.pk_cpm_key] = formValues[method.pk_cpm_key];
        //   return acc;
        // }, {});
        const offlineData = OfflinePaymentData.map(method => ({
          pk_cpm_key: method.pk_cpm_key,
          amount: formValues[method.pk_cpm_key] || 0,
        }));
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key,
          mode: "centerOfflineAmount",
          fk_cent_key: sessionStorage.getItem('key'),
          offline_data: offlineData,
        });
        if (response.data.success === true && parseInt(response.data.data) === 1) {
          toast.success("Success! Offline payments amount set.", { autoClose: 3000 });
          fetchOfflinePaymentData();
          setIsSubmitted(false);
        } else {
          toast.error("Failure! Unable to offline payments amount set. Try again later", { autoClose: 3000 });
          setIsSubmitted(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to offline payments amount set. Try again later", { autoClose: 3000 });
        setIsSubmitted(false);
      }
    },
  });

  
  return (
    <div className="page-wrapper">
        <ToastContainer position="top-center" />
            <Modal show={showDelete} variant="danger">

              <Modal.Header>Fees</Modal.Header>

              <Modal.Body>
              <p className='text-center'>Note: The new price affects only payment non-authorized students.</p>
                <p className='text-center'>Are you sure? You want change to activate this price? </p>

              </Modal.Body>
              <Modal.Footer className='pt-0'>
                <div className="mx-auto d-flex gap-2" >

                  <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
                  <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitDelete()} >Yes</Button>
                </div>

              </Modal.Footer>

              </Modal>
      {/* --------------Page Start--------------- */}
      {/* --------------Section Start--------------- */}
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h5 className='page-title'> Fees</h5>

          <div role="presentation" >
            <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
              <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


              <Typography color="text.primary">Fees</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div>
          <div className='d-flex align-items-center gap-2'>
           

             {isActive == "first" && (
              <NavLink className="primary-filled rounded-10 p-2" to='/Add-pricing'>
                <AddIcon className="btn-icon" /> New Fees</NavLink>
            )}
{/*
            {isActive == "second" && (
              <NavLink className="primary-filled rounded-10 p-2" to='/Add-discount'>
                <AddIcon className="btn-icon" /> New Discount</NavLink>
            )} */}
          </div>
        </div>

      </div>
      <Card className='my-card p-1 bg-white'>

        <Card.Body>

          {/* -------------Content Start--------------- */}

          <Tab.Container defaultActiveKey="first">
            <Row>
              <Col sm={12} className='border-bottom mb-2'>

                <Nav variant="pills" className="mb-3 text-dark">
                  <div className='d-flex justify-content-between'>
                    <Nav.Item>
                      <Nav.Link eventKey="first" className="px-4 py-2 text-dark me-2" onClick={() => handleClick("first")} >Online</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="second" className="px-4 py-2 text-dark" onClick={() => handleClick("second")} >Discount</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="third"
                        className="px-4 py-2 text-dark"
                        onClick={() => handleClick("third")}
                      >
                        Offline
                      </Nav.Link>
                    </Nav.Item>
                  </div>
                </Nav>
              </Col>

              <Col sm={12}>

                <Tab.Content>
                  <Tab.Pane eventKey="first">

                 

                    <Box
                      sx={{
                        height: "fit-content",
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#e3e6ee",
                        },
                      }}
                      style={{ height: '100%', width: "100%" }}
                    >
                     <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                        //sx={{ border: "none", m: 2 }}
                        autoHeight
                        slots={{
                          loadingOverlay: LinearProgress,
                          //noRowsOverlay: CustomNoRowsOverlay,
                          toolbar: GridToolbar,
                          // toolbar: EditToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { fileName: "FeesList" },
                            printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "FeesList" },
                            records,
                            showQuickFilter: true
                          },


                        }}
                        initialState={{
                          ...records.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        showQuickFilter={true}

                        pageSizeOptions={[10, 25, 50, 100]}

                        loading={loading}
                        rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                        columns={columns}
                        rowHeight={60} density="compact"
                        onRowClick={(params) => handleRowClick(params.row)}

                        disableRowSelectionOnClick
                        showCellVerticalBorder={true}
                        showColumnVerticalBorder={true}

                      />


                    </Box>


                  </Tab.Pane>

                  <Tab.Pane eventKey="second">

                    <Box
                      sx={{
                        height: "fit-content",
                        width: "100%",
                        "& .actions": {
                          color: "text.secondary",
                        },
                        "& .textPrimary": {
                          color: "text.primary",
                        },
                        "& .super-app-theme--header": {
                          backgroundColor: "#e3e6ee",
                        },
                      }}
                      style={{ height: '100%', width: "100%" }}
                    >
                     <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                        //sx={{ border: "none", m: 2 }}
                        autoHeight
                        slots={{
                          loadingOverlay: LinearProgress,
                          //noRowsOverlay: CustomNoRowsOverlay,
                          toolbar: GridToolbar,
                          // toolbar: EditToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { fileName: "FeesList" },
                            printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "FeesList" },
                            records_d,
                            showQuickFilter: true
                          },


                        }}
                        initialState={{
                          ...records_d.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        showQuickFilter={true}

                        pageSizeOptions={[10, 25, 50, 100]}

                        loading={loading}
                        rows={records_d.map((row, index) => ({ ...row, id: `row-${index}` }))}
                        columns={columns_d}
                        rowHeight={60} density="compact"
                        onRowClick={(params) => handleRowClick_discount(params.row)}

                        disableRowSelectionOnClick
                        showCellVerticalBorder={true}
                        showColumnVerticalBorder={true}

                      />


                    </Box>


                  </Tab.Pane>
                  <Tab.Pane eventKey="third">

                    <Form onSubmit={formik.handleSubmit}>
                      {OfflinePaymentData.map((method) => (
                        <Row className="mb-3" key={method.pk_cpm_key}>
                          <Col lg="4" md="4" sm="12" className="position-relative">
                            <h6 className="form-lable mt-4">{method.pm_name}</h6>
                          </Col>
                          <Col lg="6" md="6" sm="12" className="position-relative">
                            <h6 className="form-lable required-field">Fees</h6>
                            <Form.Control
                              type="number"
                              name={method.pk_cpm_key}
                              value={formik.values[method.pk_cpm_key]}
                              id={method.pk_cpm_key}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              step="0.01" // Ensures decimal precision
                              
                            />
                              {formik.errors[method.pk_cpm_key] && formik.touched[method.pk_cpm_key] && <p className="error">{formik.errors[method.pk_cpm_key]}</p>}
                            
                          </Col>
                        </Row>
                      ))}
                      {OfflinePaymentData.length > 0 ? <div class="d-flex justify-content-end align-items-end">
                      <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitted}>Submit</Button>
                      </div> : <p><span className="required-field"></span>Please inform the Super Admin about the offline payment mode configuration.</p> }
                      {/* <button type="submit" className="btn btn-primary" >
                        Submit
                      </button> */}
                    </Form>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
      </Card>
      {/* --------------Section End--------------- */}
      {/* --------------Page End--------------- */}
    </div>
  );
}

export default Fees;