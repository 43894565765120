
/*
Purpose: add subject details
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useEffect, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import './add-student.css';
import { security_key, BASE_URL, options_method_payment} from "../global";
import Select from 'react-select'



function AddProduct() {



    const navigate = useNavigate();
    

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const [options_pay_method, setOptionPayMethod] = useState([]);
    const [options_frequency_type, setFrequencyData] = useState([]);
    const [options_product_type, setProductTypeData] = useState([]);
    const [StripeData, setStripeData] = useState([]);
    
    //API Payment Mode start
    const fetchFrequencyData = async () => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getAllFrequencyCentre",
                fk_cent_key: sessionStorage.getItem('key')
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                     // Assuming the API response is an array of objects with 'id' and 'value' properties
                    const formattedOptionsFrequency = response.data.data.map(option => ({
                        value: option.pk_fm_key,
                        label: option.fm_name
                    }));
                    formattedOptionsFrequency.unshift({
                        value: '',
                        label: 'Select'
                    });
                    setFrequencyData(formattedOptionsFrequency);   
                    
                }else{
                    setFrequencyData([{
                        value: '',
                        label: 'Select'
                    }]);   
                   
                }
            } else {
                setFrequencyData([{
                        value: '',
                        label: 'Select'
                    }]);   
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        try {
            const response_type = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getProductType"
            });

            if (response_type.data.success === true) {
                if (response_type.data.data.length > 0) {
                     // Assuming the API response is an array of objects with 'id' and 'value' properties
                    const formattedOptionsType = response_type.data.data.map(option => ({
                        value: option.pk_pt_key,
                        label: option.pt_name
                    }));
                    formattedOptionsType.unshift({
                        value: '',
                        label: 'Select'
                    });
                    setProductTypeData(formattedOptionsType);   
                    
                }else{
                    setProductTypeData([{
                        value: '',
                        label: 'Select'
                    }]);   
                   
                }
            } else {
                setProductTypeData([{
                        value: '',
                        label: 'Select'
                    }]);   
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        try {
            const responseExistingStripe = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getCenterStripeDetailsAccess",
                pk_cent_key: sessionStorage.getItem('key'),
            });

            if (responseExistingStripe.data.success === true) {
                if (responseExistingStripe.data.data.length > 0) {
                     // Assuming the API response is an array of objects with 'id' and 'value' properties
                     setStripeData(responseExistingStripe.data.data[0]);
                     const options_pay_method = [
                        { value: '1', label: 'Online' },
                        { value: '2', label: 'Offline' }
                      ];
                      setOptionPayMethod(options_pay_method);
                    
                }else{
                    
                    const options_pay_method = [
                        { value: '2', label: 'Offline' }
                      ];
                      setOptionPayMethod(options_pay_method);
                   
                }
            } else {
                const options_pay_method = [
                    { value: '2', label: 'Offline' }
                  ];
                  setOptionPayMethod(options_pay_method);
               
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchFrequencyData();
    }, []);

    // API Payment mode end

    
//       const basicSchema = yup.object().shape({
//                 fk_pt_key: yup.mixed().required("Product type is required."),
//         pp_name : yup.string().required("Product name is required.").min(2, "Minimum 2 characters is required.").max(50, "Maximum 50 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
//         pp_count_session: yup.number()
//                             .integer()
//                             .when(['fk_pt_key', 'pp_method'], (fk_pt_key, pp_method, schema) =>
//                             ((fk_pt_key && fk_pt_key.includes("BC5182862976265C")) || parseInt(pp_method) === 2) ? schema.notRequired().min(0, "Minimum value 0 required") : schema.required("No of session is required.").min(1, "Minimum value 1 required")
//       ),

//         pp_amount: yup.number().when('fk_pt_key', (fk_pt_key, schema) => 
//             fk_pt_key && fk_pt_key.includes("BC5182862976265C") ? schema.notRequired() : schema.min(1, "Minimum value 1 required").required("Amount is required.")
// ),
//         pp_pay_method : yup.mixed().required("Payment method is required."),
//         fk_fm_key : yup.mixed().required("Frequency is required."),
//         pp_total_amount: yup.number().min(1, "Minimum value 1 required").required("Total Amount is required."),

//         pp_method: yup.number().when('fk_pt_key', (fk_pt_key, schema) => 
//             fk_pt_key && fk_pt_key.includes("BC5182862976265C") ? schema.notRequired() : schema.required("Method of payment is required.")
//           ),
//         pp_free_session: yup.number()
//         .min(0, "Minimum value 0 required")
//         .when(['fk_pt_key', 'pp_method'], (fk_pt_key, pp_method, schema) => 
//             ((fk_pt_key && fk_pt_key.includes("BC5182862976265C")) || parseInt(pp_method) === 2) ? schema.notRequired() : schema.required("No of free sessions is required.")
//           )
//         .test(
//           'is-less-than-pp_count_session',
//           'No of free sessions cannot be greater than no of sessions.',
//           function(value) {
//             const { pp_count_session } = this.parent;
//             return value <= pp_count_session;
//           }
//         ),
//         pp_chargeble_session: yup.number()
//                             .when(['fk_pt_key', 'pp_method'], (fk_pt_key, pp_method, schema) =>  
//                                 ((fk_pt_key && fk_pt_key.includes("BC5182862976265C")) || parseInt(pp_method) === 2) ? schema.min(0, "Minimum value 0 required").notRequired() : schema.min(0, "Minimum value 0 required").required("No of chargeable sessions is required.")
//                             ),
//         // Conditional validation based on pp_pay_method value
//         stp_price_id: yup
//         .string()
//         .when('pp_pay_method', (pp_pay_method, schema) => {

//             return pp_pay_method.length > 0 && parseInt(pp_pay_method[0]) === 1 
//                 ? schema.required("Stripe Price ID is required.")
//                         .min(2, "Minimum 2 characters required.")
//                         .max(250, "Maximum 250 characters is required.")
//                 : schema.notRequired();
//         }),

//         stp_price_meter: yup
//         .string()
//         .when(['pp_pay_method', 'fk_pt_key'], (pp_pay_method, fk_pt_key, schema) => {
//           return (
//             Array.isArray(fk_pt_key) && 
//             !fk_pt_key.includes("BC5182862976265C") && 
//             pp_pay_method && 
//             pp_pay_method.length > 0 && 
//             parseInt(pp_pay_method[0]) === 1
//           )
//             ? schema
//                 .required("Stripe Price Meter Name is required.")
//                 .min(2, "Minimum 2 characters required.")
//                 .max(250, "Maximum 250 characters is required.")
//             : yup.string().nullable(); // Fallback to an unvalidated string
//         })
      
      


//         // stp_price_meter: yup
//         //     .string()
//         //     .when('pp_pay_method', (pp_pay_method, schema) => {
//         //         return pp_pay_method.length > 0 && parseInt(pp_pay_method[0]) === 1  
//         //             ? schema.required("Stripe Price Meter Name is required.")
//         //                     .min(2, "Minimum 2 characters required.")
//         //                     .max(250, "Maximum 250 characters is required.")
//         //             : schema.notRequired();
//         //     })
        
        
//     });

const basicSchema = yup.object().shape({
    fk_pt_key: yup.mixed().required("Product type is required."),
    
    pp_name: yup
      .string()
      .required("Product name is required.")
      .min(2, "Minimum 2 characters is required.")
      .max(50, "Maximum 50 characters is required.")
      .matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma")
      .matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/, "Avoid apostrophe & quotes."),
  
    pp_pay_method: yup.mixed().required("Payment method is required."),
    pp_count_session: yup
      .number()
      .integer("Must be an integer")
      .min(1, "Minimum value 1 required") // Ensure minimum value for required validation
      .when(['fk_pt_key', 'pp_method'], (fk_pt_key, pp_method) => {
        console.log(pp_method);
        console.log(fk_pt_key);
        if ((fk_pt_key && fk_pt_key.includes("BC5182862976265C") || fk_pt_key.includes("2"))) {
          return yup.number().min(0, "Minimum value 0 required").notRequired();
        } else {
          return yup.number().required("No of sessions is required.").min(1, "Minimum value 1 required");
        }
      }),
  
    pp_amount: yup
      .number()
      .when('fk_pt_key', (fk_pt_key) => {
        if (fk_pt_key && fk_pt_key.includes("BC5182862976265C")) {
          return yup.number().notRequired();
        } else {
          return yup.number().min(1, "Minimum value 1 required").required("Amount is required.");
        }
      }),
  
    
    fk_fm_key: yup.mixed().required("Frequency is required."),
    
    pp_total_amount: yup.number().when('pp_method', (pp_method) => {
        if (pp_method && pp_method.includes("2")) {
          return yup.number().notRequired();
        } else {
          return yup.number().min(1, "Minimum value 1 required").required("Total Amount is required.");
        }
      }
    ),
  
    pp_method: yup
      .mixed()
      .when('fk_pt_key', (fk_pt_key) => {
        if (fk_pt_key && fk_pt_key.includes("BC5182862976265C")) {
          return yup.mixed().notRequired();
        } else {
          return yup.mixed().required("Method of payment is required.");
        }
      }),


  

  
    pp_free_session: yup
      .number()
      .min(0, "Minimum value 0 required")
      .when(['fk_pt_key', 'pp_method'], (fk_pt_key, pp_method) => {
        if ((fk_pt_key && fk_pt_key.includes("BC5182862976265C") || fk_pt_key.includes("2"))) {
          return yup.number().notRequired();
        } else {
          return yup.number().required("No of free sessions is required.").test(
            'is-less-than-pp_count_session',
            'No of free sessions cannot be greater than no of sessions.',
            function(value) {
                console.log(value);
              const { pp_count_session } = this.parent;
              return value === undefined || value <= pp_count_session;
            }
          );
        }
      }),
  
    pp_chargeble_session: yup
      .number()
      .min(0, "Minimum value 0 required")
      .when(['fk_pt_key', 'pp_method'], (fk_pt_key, pp_method) => {
        if ((fk_pt_key && fk_pt_key.includes("BC5182862976265C") || fk_pt_key.includes("2"))) {
          return yup.number().min(0, "Minimum value 0 required").notRequired();
        } else {
          return yup.number().required("No of chargeable sessions is required.");
        }
      }),
  
    stp_price_id: yup
      .string()
      .when('pp_pay_method', (pp_pay_method) => {
        if (pp_pay_method.length > 0 && parseInt(pp_pay_method[0]) === 1) {
          return yup.string()
            .required("Stripe Price ID is required.")
            .min(2, "Minimum 2 characters required.")
            .max(250, "Maximum 250 characters is required.");
        } else {
          return yup.string().notRequired();
        }
      }),
  
    stp_price_meter: yup
      .string()
      .when(['pp_pay_method', 'fk_pt_key'], (pp_pay_method, fk_pt_key) => {
        if (
          Array.isArray(fk_pt_key) &&
          !fk_pt_key.includes("BC5182862976265C") &&
          pp_pay_method &&
          pp_pay_method.length > 0 &&
          parseInt(pp_pay_method[0]) === 1
        ) {
          return yup.string()
            .required("Stripe Price Meter Name is required.")
            .min(2, "Minimum 2 characters required.")
            .max(250, "Maximum 250 characters is required.");
        } else {
          return yup.string().nullable(); // Fallback to an unvalidated string
        }
      }),
  });
  
  
    const initialValues = {
        /*Product*/
       
        pp_name : '',
        pp_count_session : 0,
        pp_chargeble_session: 0,
        pp_free_session: 0,
        pp_amount : 0, 
        pp_total_amount : 0,
        pp_pay_method : '', 
        fk_fm_key : '', 
        stp_price_id : '', 
        stp_price_meter : '', 
        stp_product_name: '',
        fk_pt_key: '',
        pp_method: '',
        fk_cent_key: sessionStorage.getItem('key'),
        created_by: sessionStorage.getItem('key'),
        visibility: 1
    };

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        enableReinitialize: true,
        onSubmit: async (formValues) => {
            // setIsSubmitting(true);
            try {
                const response = await axios.post(BASE_URL+"API/api_index.php", {
                    security_key: security_key,
                    mode: "createProduct",
                    ...formValues,
                });
                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if (parseInt(response.data.data) === 1) {
                        toast.success("Success! Your product has been added", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/Product");
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                        setIsSubmitting(false); // Re-enable the submit button
                    }else if(parseInt(response.data.data) === 2){
                        toast.error("Sorry! Product name already exists.", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to create product. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to create product. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to create product. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
    };



    const fetchCheckStripeData = async (price_Id, fk_pt_key) => {
        try {
            const response = await axios.post(BASE_URL + 'webpages/stripe/invoice.php', {
                security_key: security_key,
                mode: "getAmountStripeAccount",
                fk_cent_key: sessionStorage.getItem('key'),
                stp_price_id: price_Id,
                sa_secret_key: StripeData.sa_secret_key
            });

            if (response.data.success === true) {
                if (response.data.data && parseFloat(response.data.data) > 0) {
                     // Assuming the API response is an array of objects with 'id' and 'value' properties
                     if(fk_pt_key && fk_pt_key == 'BC5182862976265C'){
                        formik.setFieldValue('pp_total_amount', response.data.data);
                        formik.validateField('pp_total_amount');
                        formik.setFieldValue('stp_product_name', response.data.product_name);
                     }else{
                     formik.setFieldValue('pp_amount', response.data.data);
                     formik.validateField('pp_amount');

                     formik.setFieldValue('pp_total_amount', response.data.data * values.pp_chargeble_session);
                     formik.validateField('pp_total_amount');

                     formik.setFieldValue('stp_price_meter', response.data.price_meter);
                     formik.validateField('stp_price_meter');
                     formik.setFieldValue('stp_product_name', response.data.product_name);
                     }
                     
                }else{
                    formik.setFieldValue('pp_amount', 0);
                    formik.validateField('pp_amount');

                    formik.setFieldValue('pp_total_amount', 0);
                    formik.validateField('pp_total_amount');

                    formik.setFieldValue('stp_price_meter', '');
                    formik.validateField('stp_price_meter');
                    formik.setFieldValue('stp_product_name', '');
                    toast.error("Failure! Price ID unable to find your Stripe account. Please try again later.", {
                        autoClose: 3000, // 3 seconds in this example
                    });
                }
            } else {
                formik.setFieldValue('pp_amount', 0);
                formik.validateField('pp_amount');
                formik.setFieldValue('pp_total_amount', 0);
                formik.validateField('pp_total_amount');
                formik.setFieldValue('stp_price_meter', '');
                formik.validateField('stp_price_meter');
                formik.setFieldValue('stp_product_name', '');
                toast.error("Failure! Price ID unable to find your Stripe account. Please try again later.", {
                    autoClose: 3000, // 3 seconds in this example
                });
            }
        } catch (error) {
           
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        // This will run after the component renders or whenever SestTypeData changes
        if(values.pp_pay_method && values.pp_chargeble_session && values.pp_amount && parseInt(values.pp_pay_method) === 1){
            formik.setFieldValue('pp_amount', values.pp_amount);
            formik.validateField('pp_amount');

            formik.setFieldValue('pp_total_amount', values.pp_amount * values.pp_chargeble_session);
            formik.validateField('pp_total_amount');
        }else if(values.pp_pay_method && values.pp_chargeble_session && values.pp_amount && parseInt(values.pp_pay_method) === 2){

            formik.setFieldValue('pp_total_amount', values.pp_amount * values.pp_chargeble_session);
            formik.validateField('pp_total_amount');
        }
        
      }, [values.pp_pay_method,values.pp_total_amount,values.pp_amount,values.pp_chargeble_session]);
      console.log(errors)
    return (
        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>

                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'>New Product</h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">New Product</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

                    </div>
                        {/* --------------Student Particulars--------------- */}
                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Product </h6>
                            </div>
                            <Row>
                                {/* --------------Student First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Product Name</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.pp_name}
                                        onChange={(e) => {
                                            handleChange(e);
                                          }}
                                        onBlur={handleBlur}
                                        id="pp_name"
                                        className={errors.pp_name && touched.pp_name ? "input-error" : ""}
                                    />
                                    {errors.pp_name && touched.pp_name && <p className="error">{errors.pp_name}</p>}
                                </Col>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Product Type</h6>
                                    <Select menuPlacement="auto" options={options_product_type}
                                        name="fk_pt_key"
                                        value={options_product_type.find(option => option.value && values.fk_pt_key && option.value.toString() === values.fk_pt_key.toString())}
                                        id="fk_pt_key"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "fk_pt_key",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            formik.setFieldValue('pp_count_session', 0);
                                            //formik.validateField('pp_amount');
                                            formik.setFieldValue('pp_free_session', 0);
                                            //formik.validateField('pp_amount');
                                            formik.setFieldValue('pp_method', '');

                                            formik.setFieldValue('stp_price_id', '');
                                            formik.setFieldValue('pp_chargeble_session', 0);
                                           // formik.validateField('pp_amount');
                                            formik.setFieldValue('pp_amount', 0);
                                            //formik.validateField('pp_amount');
                                            formik.setFieldValue('pp_total_amount', 0);
                                            //formik.validateField('pp_total_amount');
                                            formik.setFieldValue('stp_price_meter', '');
                                            //formik.validateField('stp_price_meter');
                                            formik.setFieldValue('stp_product_name', '');
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, fk_pt_key: true });
                                        }}
                                        className={errors.fk_pt_key && touched.fk_pt_key ? "input-error" : ""}
                                    />
                                    {errors.fk_pt_key && touched.fk_pt_key && <span className="error">{errors.fk_pt_key}</span>}

                                </Col>
                                
                                {values.fk_pt_key && values.fk_pt_key !== 'BC5182862976265C' ?  <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Method Of Payment</h6>
                                    <Select menuPlacement="auto" options={options_method_payment}
                                        name="pp_method"
                                        value={options_method_payment.find(option => option.value && values.pp_method && option.value.toString() === values.pp_method.toString())}
                                        id="pp_method"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "pp_method",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            if(selectedOption["value"] == 2){
                                            formik.setFieldValue('pp_count_session', 0);
                                            //formik.validateField('pp_amount');
                                            formik.setFieldValue('pp_free_session', 0);
                                            //formik.validateField('pp_amount');

                                            formik.setFieldValue('pp_chargeble_session', 0);
                                           // formik.validateField('pp_amount');
                                            //formik.validateField('pp_amount');
                                            formik.setFieldValue('pp_total_amount', 0);
                                            }

                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, pp_method: true });
                                        }}
                                        className={errors.pp_method && touched.pp_method ? "input-error" : ""}
                                    />
                                    {errors.pp_method && touched.pp_method && <span className="error">{errors.pp_method}</span>}

                                </Col> : null }
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Frequency</h6>
                                    <Select menuPlacement="auto" options={options_frequency_type}
                                        name="fk_fm_key"
                                        value={options_frequency_type.find(option => option.value && values.fk_fm_key && option.value.toString() === values.fk_fm_key.toString())}
                                        id="fk_fm_key"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "fk_fm_key",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, fk_fm_key: true });
                                        }}
                                        className={errors.fk_fm_key && touched.fk_fm_key ? "input-error" : ""}
                                    />
                                    {errors.fk_fm_key && touched.fk_fm_key && <span className="error">{errors.fk_fm_key}</span>}

                                </Col>
                                
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Payment Method</h6>
                                    <Select menuPlacement="auto" options={options_pay_method}
                                        name="pp_pay_method"
                                        value={options_pay_method.find(option => option.value && values.pp_pay_method && option.value.toString() === values.pp_pay_method.toString())}
                                        id="pp_pay_method"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "pp_pay_method",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, pp_pay_method: true });
                                        }}
                                        className={errors.pp_pay_method && touched.pp_pay_method ? "input-error" : ""}
                                    />
                                    {errors.pp_pay_method && touched.pp_pay_method && <span className="error">{errors.pp_pay_method}</span>}

                                </Col>
                                
                                {values.pp_pay_method && parseInt(values.pp_pay_method) === 1 ? 
                                <><Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Stripe Price ID</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.stp_price_id}
                                        onChange={handleChange}
                                        onBlur={(e) => {fetchCheckStripeData(e.target.value, values.fk_pt_key); handleBlur(e);}}
                                        id="stp_price_id"
                                        className={errors.stp_price_id && touched.stp_price_id ? "input-error" : ""}
                                    />
                                    {errors.stp_price_id && touched.stp_price_id && <p className="error">{errors.stp_price_id}</p>}
                                    </Col>
                                    </>
                                    : null}
                                {values.pp_pay_method && parseInt(values.pp_pay_method) === 1 && 
                                values.fk_pt_key && values.fk_pt_key !== 'BC5182862976265C' ? 
                                 <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Stripe Price Meter Name (Auto)</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.stp_price_meter}
                                        onChange={handleChange}
                                        onBlur={(e) => {handleBlur}}
                                        id="stp_price_meter"
                                        className={errors.stp_price_meter && touched.stp_price_meter ? "input-error" : ""}
                                        disabled={true}
                                    />

                                    {errors.stp_price_meter && touched.stp_price_meter && <p className="error">{errors.stp_price_meter}</p>}
                                </Col> : null}
                                
                                {values.fk_pt_key && values.fk_pt_key !== 'BC5182862976265C'
                                && (values.pp_method && parseInt(values.pp_method) === 1) ? 
                                <>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">No Of Session</h6>
                                    <Form.Control
                                        type="number"
                                        id="pp_count_session"
                                        
                                        value={values.pp_count_session}
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (e.target.value && values.pp_free_session && e.target.value >= values.pp_free_session ) {
                                                formik.setFieldValue('pp_chargeble_session', e.target.value - values.pp_free_session);
                                                formik.validateField('pp_chargeble_session');
                                                
                                                //formik.validateField('pp_count_session');
                                            }else if(e.target.value && values.pp_free_session === 0){
                                                formik.setFieldValue('pp_chargeble_session', e.target.value);
                                            } else {
                                                formik.setFieldValue('pp_chargeble_session', 0);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        className={errors.pp_count_session && touched.pp_count_session ? "input-error" : ""}
                                    />
                                    {errors.pp_count_session && touched.pp_count_session && <p className="error">{errors.pp_count_session}</p>}
                                </Col>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">No Of Free Session</h6>
                                    <Form.Control
                                        type="number"
                                        id="pp_free_session"
                                        
                                        value={values.pp_free_session}
                                        onChange={(e) => {
                                            handleChange(e);
                                            if (e.target.value && values.pp_count_session && e.target.value <= values.pp_count_session ) {
                                                formik.setFieldValue('pp_chargeble_session', values.pp_count_session - e.target.value);
                                                formik.validateField('pp_chargeble_session');
                                            } else {
                                                formik.setFieldValue('pp_chargeble_session', 0);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        className={errors.pp_free_session && touched.pp_free_session ? "input-error" : ""}
                                    />
                                    {errors.pp_free_session && touched.pp_free_session && <p className="error">{errors.pp_free_session}</p>}
                                </Col>
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Chargeable Session (Auto)</h6>
                                    <Form.Control
                                        type="number"
                                        id="pp_chargeble_session"
                                        value={values.pp_chargeble_session}
                                        onChange={(e) => {
                                            handleChange(e);
                                            console.log(e.target.value)
                                            if (e.target.value && values.pp_total_amount && values.pp_pay_method && parseInt(values.pp_pay_method) === 1) {
                                                formik.setFieldValue('pp_amount', values.pp_total_amount / e.target.value);
                                                formik.validateField('pp_amount');
                                            }else  if (e.target.value && values.pp_amount && values.pp_pay_method && parseInt(values.pp_pay_method) === 2) {
                                                formik.setFieldValue('pp_amount', values.pp_amount * e.target.value);
                                                formik.validateField('pp_amount');
                                            } else {
                                                formik.setFieldValue('pp_amount', 0);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                        className={errors.pp_chargeble_session && touched.pp_chargeble_session ? "input-error" : ""}
                                        disabled={true}
                                    />
                                    {errors.pp_chargeble_session && touched.pp_chargeble_session && <p className="error">{errors.pp_chargeble_session}</p>}
                                </Col> 
                                </>
                                : null}
                                {(values.fk_pt_key && values.fk_pt_key !== 'BC5182862976265C') ? 
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Per Session Amount {values.pp_pay_method && parseInt(values.pp_pay_method) === 1 ? '(Auto)': null} - {sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'}</h6>
                                    <Form.Control
                                        type="number"
                                        id="pp_amount"
                                        value={values.pp_amount}
                                        onChange={(e) => {
                                            handleChange(e);
                                            // if (e.target.value && values.pp_chargeble_session && values.pp_pay_method && parseInt(values.pp_pay_method) === 2) {
                                            //     formik.setFieldValue('pp_total_amount', values.pp_chargeble_session * e.target.value);
                                            //     formik.validateField('pp_total_amount');
                                            // } else {
                                            //     formik.setFieldValue('pp_total_amount', 0);
                                            // }
                                        }}
                                        onBlur={handleBlur}
                                        className={errors.pp_amount && touched.pp_amount ? "input-error" : ""}
                                        disabled={values.pp_pay_method && parseInt(values.pp_pay_method) === 1? true : false}
                                    />
                                    {errors.pp_amount && touched.pp_amount && <p className="error">{errors.pp_amount}</p>}
                                </Col>
                                : null}
                                
                                {values.pp_method && parseInt(values.pp_method) === 2 ? null : 
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Total Amount {(values.pp_pay_method && parseInt(values.pp_pay_method) === 1) || (values.fk_pt_key && values.fk_pt_key !== 'BC5182862976265C')  ? '(Auto)': null} - {sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'}</h6>
                                    <Form.Control
                                        type="number"
                                        id="pp_total_amount"
                                        value={values.pp_total_amount}
                                         onChange={(e) => {
                                            handleChange(e);
                                            // if (e.target.value && values.pp_chargeble_session && values.pp_pay_method && parseInt(values.pp_pay_method) === 2) {
                                            //     formik.setFieldValue('pp_total_amount', e.target.value / values.pp_chargeble_session);
                                            //     formik.validateField('pp_total_amount');
                                            // } else {
                                            //     formik.setFieldValue('pp_total_amount', 0);
                                            // }
                                        }}
                                        onBlur={handleBlur}
                                        className={errors.pp_total_amount && touched.pp_total_amount ? "input-error" : ""}
                                        disabled={(values.pp_pay_method && parseInt(values.pp_pay_method) === 1)|| (values.fk_pt_key && values.fk_pt_key !== 'BC5182862976265C') ? true : false}
                                    />
                                    {errors.pp_total_amount && touched.pp_total_amount && <p className="error">{errors.pp_total_amount}</p>}
                                </Col>
                                }
                            </Row>
                        </div>
                    </Stack>
                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                         
                            <Button variant="contained" className="primary-gray me-2" onClick={() => navigate('/Product')}>Cancel</Button>
                        <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>
                </div>
            </Form>
        </>
    );
}

export default AddProduct;